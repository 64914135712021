<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="accent text-h5 font-weight-bold pa-3">
        <v-btn :to="{ path: '/networkings' }" small exact fab text class="mr-2">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-icon color="primary" left>mdi-wan</v-icon>
        Networkings - {{ user_nome }}
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-card flat>
          <v-card-title>
            <v-text-field
              style="max-width: 400px"
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              clearable
              dense
              hide-details
            ></v-text-field>

            <v-spacer></v-spacer>
            <v-btn
              :to="{ path: '/networkings/adicionar' }"
              align="center"
              color="xbColor"
              class="white--text"
            >
              <v-icon left> mdi-plus</v-icon>
              Adicionar
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :search="search"
              :items="networkings"
              :loading="loading"
              :items-per-page="10"
              :sort-desc="true"
              class="data-tables data-tables__row-click"
            >
              <template v-slot:item.delete="{ item }">
                <v-btn text fab small @click="deletarNetworking(item)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {
  index as fetchNetworkings,
  deleteNetworking,
} from "@/api/admin/networking.js";

export default {
  name: "Networkings",

  data() {
    return {
      loading: true,
      networkings: [],
      search: "",
    };
  },

  computed: {
    headers() {
      return [
        {
          text: "Nome Contato",
          value: "nome",
        },
        {
          text: "Telefone Contato",
          value: "telefone",
        },
        {
          text: "Ramo Atividade",
          value: "ramo_atividade",
        },
        {
          text: "Deletar",
          value: "delete",
        },
      ];
    },
    user_id() {
      return this.$route.params.userId;
    },
    user_nome() {
      return this.$route.query.nome;
    },
  },

  methods: {
    goToNetworking(item) {
      this.$router.push({ path: `/networking/${item.id}` });
    },
    getNetworkings() {
      this.loading = true;
      fetchNetworkings(this.user_id)
        .then((response) => {
          this.networkings = response;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    deletarNetworking(item) {
      deleteNetworking(item.id).then((response) => {
        if (response.status === 200) {
          this.$toast.success("Networking deletado!");
          this.getNetworkings();
        }
      });
    },
  },

  mounted() {
    this.getNetworkings();
    console.log(this.$route.query);
  },
};
</script>

<style></style>
